import "/src/assets/scss/main.scss";
import lozad from "lozad";
import Swiper, { Autoplay, Controller, Navigation } from "swiper";
Swiper.use([Autoplay, Controller, Navigation]);
import "swiper/css";

window.document.addEventListener("DOMContentLoaded", () => {
  window.observer = lozad(".lozad", {
    rootMargin: "-50px 0px", // syntax similar to that of CSS Margin
    threshold: 0, // ratio of element convergence
    enableAutoReload: true, // it will reload the new image when validating attributes changes
  });
  window.observer.observe();

  const doc = document.documentElement;
  doc.style.setProperty("--doc-height", `${window.innerHeight}px`);

  /*** Header on scroll ***/
  const header = document.querySelector("header");
  window.addEventListener("scroll", () => {
    if (window.scrollY > 200) {
      header.classList.add("is-sticky");
      header.style.transform = `translateY(0)`;
    } else {
      header.style.transform = `translateY(-${window.scrollY}px)`;
      header.classList.remove("is-sticky");
    }
  });
  /***/

  /*** Features Slider ***/
  const featuresSliderConfig = {
    spaceBetween: 16,
    slidesPerView: 2,
    speed: 3000,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".features-slider__nextbtn",
      prevEl: ".features-slider__prevbtn",
    },
    breakpoints: {
      768: {
        slidesPerView: "auto",
        spaceBetween: 64,
      },
      1440: {
        slidesPerView: "auto",
        spaceBetween: 64,
      },
    },
    on: {
      autoplayTimeLeft(s, time, progress) {
        document.querySelector(".features-slider__nextbtn").style.setProperty("--features-slider-progress", 1 - progress);
      },
      init(s) {
        const numberOfSlides = s.slides.length;
        if (numberOfSlides <= 4 && window.innerWidth >= 1440) {
          document.querySelector(".features-slider__navigation").style.display = "none";
        }
      },
    },
  };
  const featuresSlider = new Swiper(".features-slider", featuresSliderConfig);
  /***/

  /*** Partners Slider ***/
  const partnersSliderConfig = {
    spaceBetween: 16,
    slidesPerView: 3,
    speed: 3000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
    navigation: {
      nextEl: ".partners-slider__nextbtn",
      prevEl: ".partners-slider__prevbtn",
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
      },
      1440: {
        slidesPerView: 6,
      },
      1920: {
        slidesPerView: 7,
      },
    },
    on: {
      autoplayTimeLeft(s, time, progress) {
        document.querySelector(".partners-slider__nextbtn").style.setProperty("--partners-slider-progress", 1 - progress);
      },
    },
  };
  const partnersSlider = new Swiper(".partners-slider", partnersSliderConfig);
  /***/

  /*** Faq ***/
  document.querySelectorAll(".faq-item button").forEach((button) => {
    button.addEventListener("click", () => {
      button.parentElement.classList.toggle("is-expanded");
    });
  });
  /***/

  /*** Burger Menu ***/
  document.querySelector(".header__burger").addEventListener("click", () => {
    document.querySelector(".aside-nav").classList.add("is-active");
    document.body.classList.add("over-hidden");
  });
  document.querySelector(".aside-nav button").addEventListener("click", () => {
    document.querySelector(".aside-nav").classList.remove("is-active");
    document.body.classList.remove("over-hidden");
  });
  /***/

  /*** Header Contact Menu ***/
  document.querySelector(".header__contact button").addEventListener("click", () => {
    document.querySelector(".header__contact-menu").classList.toggle("is-active");
  });
  /***/

  /*** Outside click ***/
  window.addEventListener("click", (e) => {
    if (e.target.classList.contains("aside-nav")) {
      document.querySelector(".aside-nav").classList.remove("is-active");
      document.body.classList.remove("over-hidden");
    }
    if (!e.target.closest(".header__contact-menu") && !e.target.closest(".header__contact button")) {
      document.querySelector(".header__contact-menu").classList.remove("is-active");
    }
  });
  /***/

  document.querySelectorAll(".btn-event-modal-close").forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector(".contact-modal").classList.remove("is-active");
    });
  });
  document.querySelectorAll(".btn-event-modal").forEach((button) => {
    button.addEventListener("click", (e) => {
      e.preventDefault();
      document.querySelector(".contact-modal").classList.add("is-active");
    });
  });
});
